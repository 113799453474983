export const BEGIN_AJAX_CALL_SEARCH = 'BEGIN_AJAX_CALL_SEARCH';

export const BEGIN_AJAX_CALL_LOAD = 'BEGIN_AJAX_CALL_LOAD';

export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

export const INPUT_REALTOR_NAME = 'INPUT_REALTOR_NAME';

export const INPUT_REALTOR_ROLE = 'INPUT_REALTOR_ROLE';

export const SEARCH_REALTOR_SUCCESS = 'SEARCH_REALTOR_SUCCESS';

export const LOAD_REALTORSTAT_SUCCESS = 'LOAD_REALTORSTAT_SUCCESS';

export const SELECT_REALTOR_VIEW_STAT = 'SELECT_REALTOR_VIEW_STAT';

export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

export const CLEAR_REALTOR_STATS = 'CLEAR_REALTOR_STATS';

export const SHOW_RESULTS = 'SHOW_RESULTS';

export const ERROR_MESSAGE = 'ERROR_MESSAGE';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

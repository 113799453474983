import React from 'react';
import LoadingDots from './LoadingDots';

const SearchStatus = ({searching, realtors}) => {
  if (searching) {
    return (
      <h6>Searching<LoadingDots /></h6>
    );
  }
  else {
    return (
      <h6>Found {realtors.length} {realtors.length > 1 ? "matches. " : "match. "}{realtors.length > 0 && "Click to view statistics."}</h6>
    );
  }
};

export default SearchStatus;

import React from 'react';

const Footer = () => (
  <div className="text-center">
    <nav className="navbar navbar-expand-sm justify-content-center">
      <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#aboutModal">
        About
      </button> |
      <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#termsModal">
        Terms
      </button> |
      <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#privacyModal">
        Privacy
      </button> |
      <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#contactModal">
        Support
      </button>
    </nav>
    <small>&copy; Copyright 2018-{(new Date().getFullYear())}, RealtorCheck.com</small>
  </div>
);

export default Footer;

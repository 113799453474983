import RealtorApi from '../../api/realtorApi';
import * as types from './actionTypes';
import { beginAjaxCallSearch, beginAjaxCallLoad, ajaxCallError } from './ajaxStatusActions';
import { receiveErrorMessage, resetErrorMessage } from './errorActions';
import initialStats from '../reducers/initialState';

export const loadRealtorStatSuccess = realtorStat => ({
  type: types.LOAD_REALTORSTAT_SUCCESS,
  realtorStat
});

export const inputRealtorName = inputName => ({
  type: types.INPUT_REALTOR_NAME,
  inputName
});

export const inputRealtorRole = inputRole => ({
  type: types.INPUT_REALTOR_ROLE,
  inputRole
});

export const searchRealtorSuccess = realtors => ({
  type: types.SEARCH_REALTOR_SUCCESS,
  realtors
});

export const clearSearchResults = () => ({
  type: types.CLEAR_SEARCH_RESULTS,
});

export const showResults = shouldShow => ({
  type: types.SHOW_RESULTS,
  shouldShow
})

export const searchRealtor = realtorName => dispatch => {
  dispatch(beginAjaxCallSearch());
  dispatch(inputRealtorName(realtorName));
  dispatch(clearRealtorStats());
  dispatch(clearSearchResults());
  dispatch(selectRealtorViewStat(''));
  dispatch(resetErrorMessage());
  dispatch(showResults(true));
  return RealtorApi.findRealtorByName(realtorName).then(response => {
    if (Array.isArray(response)) {
      dispatch(searchRealtorSuccess(response));
    }
    else if (response.hasOwnProperty('msg')) {
      //console.log('111',response);
      dispatch(receiveErrorMessage(response.msg));
      dispatch(ajaxCallError());
    }

    //dispatch(showResults(true));
  }).catch(error => {
    //console.log('222',error);
    throw(error);
  });
};


export const selectRealtorViewStat = realtorId => ({
  type: types.SELECT_REALTOR_VIEW_STAT,
  realtorId
});

export const clearRealtorStats = () => ({
  type: types.CLEAR_REALTOR_STATS,
});

export const viewRealtorStat = (realtorRole, realtorId) => dispatch => {
  dispatch(beginAjaxCallLoad());
  dispatch(selectRealtorViewStat(realtorId));
  dispatch(resetErrorMessage());
  return RealtorApi.getRealtorStat(realtorRole, realtorId).then(response => {
    if (response.hasOwnProperty('Stats')) {
      dispatch(loadRealtorStatSuccess(response));
    }
    else if (response.hasOwnProperty('msg')) {
      //console.log('333',response);
      dispatch(receiveErrorMessage(response.msg));
      dispatch(ajaxCallError());
    }
  }).catch(error => {
    throw(error);
  });
}

export const selectRealtorRole = realtorRole => dispatch => {
  dispatch(inputRealtorRole(realtorRole));
  dispatch(showResults(false));
  dispatch(clearRealtorStats());
  dispatch(clearSearchResults());
  dispatch(selectRealtorViewStat(''));
}

import * as ActionTypes from '../actions/actionTypes';
import { combineReducers } from 'redux';
import inputName from './realtorNameReducer';
import inputRole from './realtorRoleReducer';
import matchedRealtors from './matchedRealtorsReducer';
import realtorStats from './realtorStatReducer';
import viewStatForId from './selectRealtorViewStatReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import showResults from './showResultsReducer';
import errorMessage from './errorReducer';

const rootReducer = combineReducers({
  inputName,
  inputRole,
  matchedRealtors,
  realtorStats,
  viewStatForId,
  ajaxCallsInProgress,
  showResults,
  errorMessage,
});

export default rootReducer;

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function showResultsReducer(state = initialState.showResults, action) {
  switch (action.type) {
    case types.SHOW_RESULTS:
      return action.shouldShow;
    default:
      return state;
  }
}


import * as types from './actionTypes';

export const receiveErrorMessage = error => ({
  type: types.ERROR_MESSAGE,
  error
});


export const resetErrorMessage = () => ({
  type: types.RESET_ERROR_MESSAGE
});

export default {
  inputName: "",
  inputRole: "Buyer",
  matchedRealtors: [],
  realtorStats: [],
  viewStatForId: "",
  showResults: false,
  ajaxCallsInProgress: 0,
  error: null
};

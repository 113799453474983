/* eslint-disable no-undef */

import delay from './delay';
import 'whatwg-fetch';
import getBaseUrl from './baseUrl';

const baseUrl = getBaseUrl();

function get(url) {
  return fetch(baseUrl + url).then(onSuccess, onError);
}

function onSuccess(response) {
  return response.json();
}

function onError(error) {
  console.log('realRealtorApi', error); // eslint-disable-line no-console
}

class RealtorApi {
  static getRealtorStat(role, id) {
    return get(`realtorapi/stats/${role.toLowerCase()}agent/${id}`);
  }

  static findRealtorByName(name) {
    return get(`realtorapi/realtor/${name}`);
  }
}

export default RealtorApi;

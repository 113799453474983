import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MatchedRealtors extends Component {
  static propTypes = {
    realtors: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Corp: PropTypes.string.isRequired
    })).isRequired,
    viewStat: PropTypes.func.isRequired,
    viewStatForId: PropTypes.string.isRequired
  };

  handleClick(realtor) {
    if (realtor.Id !== this.props.viewStatForId) {
      this.props.viewStat(realtor);
    }
  }

  render() {
    const { realtors, viewStat, viewStatForId } = this.props;
    const activeItem = 'list-group-item list-group-item-action active';
    const inactiveItem = 'list-group-item list-group-item-action';
    return (
      <ul className="list-group">
        {realtors.map(realtor =>
          <li
            className={viewStatForId === realtor.Id ? activeItem : inactiveItem}
            key={realtor.Id}
            onClick={this.handleClick.bind(this, realtor)}>{realtor.Name}, {realtor.Corp} (BRE#: {realtor.Id})</li>
        )}
      </ul>
    );
  }
}

import React from 'react';

const AlertPanel = ({error}) => {
  if (!error) {
    return null;
  }

  return (
    <div className="alert alert-danger mx-auto my-5 col-xs-6 col-md-10" role="alert">
      <i className="fa fa-exclamation-circle mr-2"></i>{error}
    </div>
  );
};

export default AlertPanel;

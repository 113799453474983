/*eslint-disable import/default */

//import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './containers/Root';

const store = configureStore();

render(
  <Router>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
);

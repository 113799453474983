import * as types from '../actions/actionTypes';
import initialState from './initialState';

const viewStatForId = (state = initialState.viewStatForId, action) => {
  switch (action.type) {
    case types.SELECT_REALTOR_VIEW_STAT:
      return action.realtorId;
    default:
      return state;
  }
}

export default viewStatForId;

import React from 'react';

const Header = () => (
  <nav className="navbar navbar-expand-sm justify-content-center mt-2 mb-0">
    <a className="navbar-brand mx-2" href="https://wa.realtorcheck.com/">Washington</a> |
    <a className="navbar-brand mx-2" href="#">California</a>
  </nav>
);

export default Header;

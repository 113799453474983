import * as types from './actionTypes';

export function beginAjaxCallSearch() {
  return {type: types.BEGIN_AJAX_CALL_SEARCH};
}

export function beginAjaxCallLoad() {
  return {type: types.BEGIN_AJAX_CALL_LOAD};
}

export function ajaxCallError() {
  return {type: types.AJAX_CALL_ERROR};
}

import React from 'react';

const Jumbotron = () => (
  <div className="jumbotron mx-auto pt-2 mt-0 mb-5 col-xs-6 col-md-10 align-top">
    <h2><span className="realtor">Realtor</span><span className="check">Check</span><span className="beta">beta</span></h2>
    <h5 className="my-2"><span className="state">California state</span></h5>
  </div>
);

export default Jumbotron;

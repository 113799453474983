/* eslint-disable no-debugger*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';

/* eslint-disable no-useless-escape */
const invalidTests = /[\~\`\!\@\#\$\%\^\&\*\(\)\_\+\-\=\\\|\[\]\{\}\:\;\"\<\>\,\.\/\?]/;

export default class SearchBar extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onSelectRole: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    searching: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    inputName: PropTypes.string,
    inputRole: PropTypes.string.isRequired,
    error: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {inputError: null};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setInputValue(nextProps.value);
    }
  }

  selectBuyerAgent = e => {
    e.preventDefault();
    this.props.onSelectRole('Buyer');
  }

  selectSellerAgent = e => {
    e.preventDefault();
    this.props.onSelectRole('Seller');
  }

  getInputValue = () => {
    return this.input.value.trim();
  };

  setInputValue = val => {
    // Generally mutating DOM is a bad idea in React components,
    // but doing this for a single uncontrolled field is less fuss
    // than making it controlled and maintaining a state for it.
    this.input.value = val;
  }

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let value = this.getInputValue().trim();
    if (!value) {
      this.setState({  // eslint-disable-line react/no-did-mount-set-state
        inputError: 'Input cannot be empty!'
      });
    }
    else if (invalidTests.test(value)) {
      this.setState({  // eslint-disable-line react/no-did-mount-set-state
        inputError: 'Input contains invalid characters!'
      });
    }
    else {
      this.setState({  // eslint-disable-line react/no-did-mount-set-state
        inputError: null
      });
      this.props.onSubmit(this.getInputValue());
    }
  };

  render() {
    return (
      <div>
        <form className="form mx-auto my-4 col-xs-6 col-md-10 align-items-center">
          <div className="input-group">
            <div className="input-group-prepend">
              <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                disabled={this.props.searching}
                aria-haspopup="true"
                aria-expanded="false">{this.props.inputRole} Agent</button>
              <div className="dropdown-menu">
                <button className="dropdown-item" onClick={this.selectBuyerAgent}>Buyer Agent</button>
                <button className="dropdown-item" onClick={this.selectSellerAgent}>Seller Agent</button>
              </div>
            </div>
            <input
                id="nameInput"
                type="text"
                maxLength="60"
                name={this.props.name}
                ref={(input) => this.input = input}
                className={this.state.inputError ? "form-control is-invalid" : "form-control"}
                disabled={this.props.searching}
                placeholder={this.props.placeholder}
                //value={this.props.inputName}
                onKeyUp={this.handleKeyUp}/>
            <span className="input-group-btn">
              <button
                type="submit"
                className="btn btn-primary ml-3"
                onClick={this.handleSubmit}
                disabled={this.props.searching}>
                 <span className="fa fa-search" aria-hidden="true"></span> Search
              </button>
            </span>
          </div>
          {this.state.inputError &&
            <div className="mx-auto my-2 col-xs-4 col-md-9">
              <span id="inputHelp" className="text-danger">
                {this.state.inputError}
              </span>
            </div>
          }
        </form>
        <div class="alert alert-warning alert-dismissible fade show  mx-auto my-4 col-xs-6 col-md-10" role="alert">
          RealtorCheck California (ca.realtorcheck.com) is in maintenance mode. No data update post mid 2019.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

// <span className="fa fa-search" aria-hidden="true"></span>

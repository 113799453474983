import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchStatus from './SearchStatus';
import MatchedRealtors from './MatchedRealtors';

export default class SearchResultPanel extends Component {
  static propTypes = {
    inputName: PropTypes.string.isRequired,
    searching: PropTypes.bool.isRequired,
    realtors: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Corp: PropTypes.string.isRequired
    })).isRequired,
    viewStat: PropTypes.func.isRequired,
    viewStatForId: PropTypes.string.isRequired
  };

  render() {
    const { inputName, searching, realtors, viewStat, viewStatForId, showResults, error } = this.props;
    if (error) {
      return null;
    }

    if (showResults)
    {
      return (
        <div className="panel panel-default mx-auto my-5 col-xs-6 col-md-10 align-items-center">
          <div className="panel-heading">
            <SearchStatus
              searching={searching}
              realtors={realtors} />
          </div>
          <div className="panel-body">
            {realtors.length > 0 &&
            <MatchedRealtors
              realtors={realtors}
              viewStat={viewStat}
              viewStatForId={viewStatForId} />}
          </div>
        </div>
      );
    }

    return null;
  }
}

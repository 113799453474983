import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Jumbotron from '../components/Jumbotron';
import SearchBar from '../components/SearchBar';
import SearchResultPanel from '../components/SearchResultPanel';
import StatisticsPanel from '../components/StatisticsPanel';
import { selectRealtorRole, searchRealtor, viewRealtorStat } from '../actions/realtorActions';
import AlertPanel from '../components/AlertPanel';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.css';

class App extends Component {
  static propTypes = {
    inputName: PropTypes.string.isRequired,
    inputRole: PropTypes.string.isRequired,
    showResults: PropTypes.bool.isRequired,
    searching: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    // Injected by React Redux
    errorMessage: PropTypes.string,
    //resetErrorMessage: PropTypes.func.isRequired,
    path: PropTypes.string,
    // Injected by React Router
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
    matchedRealtors: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Corp: PropTypes.string.isRequired
    })),
    viewStatForId: PropTypes.string.isRequired,
    realtorStats: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.string.isRequired
    })).isRequired
  };

  /*
  handleChange = nextValue => {
    this.props.history.push(`/${nextValue}`)
  };
  */

  handleSelectRole = realtorRole => {
    this.props.dispatch(selectRealtorRole(realtorRole));
  }

  handleSubmit = realtorName => {
    this.props.dispatch(searchRealtor(realtorName))
    //console.log(realtorName);
  }

  handleViewStat = (realtor) => {
    this.props.dispatch(viewRealtorStat(this.props.inputRole, realtor.Id))
    //console.log(realtor.Id);
  }

  render() {
    const spinner = document.getElementById('spinner');

    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true');
    }

    const { inputName, inputRole, children, path, searching, loading, matchedRealtors, viewStatForId, realtorStats, showResults, errorMessage } = this.props;
    return (
      <div className="Site">
        <div className="Site-content">
          <Header />
          <Jumbotron />
          <SearchBar
            name='realtorName'
            inputName={inputName}
            inputRole={inputRole}
            placeholder='First and last name (or BRE #)'
            searching={searching}
            onSelectRole={this.handleSelectRole}
            onSubmit={this.handleSubmit} />
          <AlertPanel
            error={errorMessage} />
          <SearchResultPanel
            error={errorMessage}
            inputName={inputName}
            showResults={showResults}
            searching={searching}
            realtors={matchedRealtors}
            viewStat={this.handleViewStat}
            viewStatForId={viewStatForId} />
          <StatisticsPanel
            error={errorMessage}
            inputRole={inputRole}
            realtorStats={realtorStats}
            realtors={matchedRealtors}
            viewStatForId={viewStatForId}
            loading={loading} />
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  inputName: state.inputName,
  inputRole: state.inputRole,
  showResults: state.showResults,
  searching: state.ajaxCallsInProgress === 1,
  loading: state.ajaxCallsInProgress === 2,
  matchedRealtors: state.matchedRealtors,
  viewStatForId: state.viewStatForId,
  realtorStats: state.realtorStats,
  errorMessage: state.errorMessage,
  //path: ownProps.location.pathname.substring(1)
});

export default withRouter(connect(mapStateToProps)(App));

import * as types from '../actions/actionTypes';
import initialState from './initialState';

const realtorStats = (state = initialState.realtorStats, action) => {
  switch (action.type) {
    case types.LOAD_REALTORSTAT_SUCCESS:
      for (const realtorStat of state) {
        if (realtorStat.Id === action.realtorStat.Id) {
          return state;
        }
      }
      return [...state, action.realtorStat];
    case types.CLEAR_REALTOR_STATS:
      return initialState.realtorStats;
    default:
      return state;
  }
}

export default realtorStats;

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function matchedRealtorsReducer(state = initialState.matchedRealtors, action) {
  switch (action.type) {
    case types.SEARCH_REALTOR_SUCCESS:
      return action.realtors;
    case types.CLEAR_SEARCH_RESULTS:
      return initialState.matchedRealtors;
    default:
      return state;
  }
}


export const CityColors = [
  '#e25667','#e27656','#e2a756','#e2d956','#b9e256','#87e256','#56e256','#56e286','#56e2b8','#56dae2','#56a8e2','#5677e2','#6556e2','#9756e2','#c856e2','#e256c9',//'#e25698'
];

export const PTypeColors = [
  '#5DA5DA','#FAA43A','#60BD68','#F17CB0','#B2912F','#B276B2',
  '#F15854', // for the rest
];

const sortByValueDesc = (a, b) => b[1] - a[1];

const sortByKeyAsc = (a, b) => a[0] - b[0];


function getSortedKeyValuePair(data, sortFunction, limit) {
  const sorted = [];
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      sorted.push([key, data[key]]);
    }
  }
  sorted.sort(sortFunction);
  if (limit && sorted.length > limit)
  {
    const removed = sorted.splice(limit);
    const rest = removed.reduce((acc, cur) => acc + cur[1], 0);
    sorted.push(['...', rest])
  }
  return sorted;
}

export function generatePieData(rawData, colors) {
  const retVal = {labels: [], datasets: [{data: [], backgroundColor: []}]};
  const sortedData = getSortedKeyValuePair(rawData, sortByValueDesc, colors.length-1);
  let i = 0;
  for (let data of sortedData) {
    retVal.labels.push(data[0]);
    retVal.datasets[0].data.push(data[1]);
    retVal.datasets[0].backgroundColor.push(colors[i++]);
  }
  return retVal;
}

function currencyFormat(n) {
  return '$'+n.toString().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1,');
}

export function generateBarData(rawData) {
  const retVal = {
    labels: [],
    datasets: [
      {
        label: 'Number of Sales',
        backgroundColor: '#b2d8ff',
        borderColor: '#0080ff',
        borderWidth: 1,
        data: []
      }
    ]
  };
  const sortedData = getSortedKeyValuePair(rawData, sortByKeyAsc);

  for (let i = 0; i < sortedData.length - 1; i++) {
    sortedData[i][0] = currencyFormat(sortedData[i][0]).concat(' - ').concat(currencyFormat(sortedData[i+1][0]-1));
  }
  sortedData[sortedData.length-1][0] = currencyFormat(sortedData[sortedData.length-1][0]).concat(' +');

  let i = 0;
  for (let data of sortedData) {
    retVal.labels.push(data[0]);
    retVal.datasets[0].data.push(data[1]);
    //retVal.datasets[0].backgroundColor.push(colors[i++]);
  }
  return retVal;
}

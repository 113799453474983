import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingDots from './LoadingDots';
import { CityColors, PTypeColors, generatePieData, generateBarData } from './ChartHelper';
import { Doughnut, Bar, Pie, Polar } from 'react-chartjs-2';

function currencyFormat(n) {
  return '$'+n.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1,');
}

const PTypeCharOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'Sales by Property Type'
  },
  legend: {
    position: 'bottom',
  },
  animation: {
    animateScale: true,
    animateRotate: true
  }
};

const CityCharOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'Sales by City'
  },
  legend: {
    position: 'left',
  },
};

const PriceChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'Sales by Price Range'
  },
  legend: {
    display: false,
  },
  scaleShowValues: true,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 6,
      }
    }],
    xAxes: [{
      ticks: {
        autoSkip: false
      }
    }]
  },
  animation: {
    easing: 'easeInQuad'
  }
};

export default class StatisticsPanel extends Component {
  static propTypes = {
    inputRole: PropTypes.string.isRequired,
    viewStatForId: PropTypes.string.isRequired,
    realtors: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Corp: PropTypes.string.isRequired
    })).isRequired,
    realtorStats: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.string.isRequired
    })).isRequired,
    loading: PropTypes.bool.isRequired
  };

  render() {
    const { viewStatForId, realtorStats, realtors, loading, inputRole, error } = this.props;
    if (error) {
      return null;
    }

    if (realtorStats.length === 0) {
      if (loading) {
        return (<h6>Loading<LoadingDots/></h6>);
      }
      return null;
    }

    const realtor = realtors.filter(realtor => realtor.Id === viewStatForId)[0];

    const filtered = realtorStats.filter(stat => stat.Id === viewStatForId);
    if (filtered.length === 0) {
      return null;
    }
    const stats = filtered[0].Stats;

    const years = [];
    for (const key in stats) {
      if (stats.hasOwnProperty(key)) {
        years.push(key);
      }
    }
    years.sort(function(a, b){return b - a});


    const cityData = {};
    const ptypeData = {};
    const priceData = {};

    years.map(year => {
      // City Data
      cityData[year] = generatePieData(stats[year].cities, CityColors);

      // Property Type Data
      ptypeData[year] = generatePieData(stats[year].propertyTypes, PTypeColors);

      // Price Range
      priceData[year] = generateBarData(stats[year].prices);
    });

    return (
      <div className="mx-auto my-5 align-items-center text-center">
        <h5>Showing annual statistics for <span className="realtor-highlight">{realtor.Name}, {realtor.Corp} (BRE#: {realtor.Id})</span> representing <span className="represent-highlight">{inputRole}</span></h5>
        {years.map(year =>
          <div className="panel panel-default mx-auto my-5 text-left"
            key={year}>
            <div className="panel-heading">
              <h3 className="year"><i className="fa fa-calendar ml-1 mr-3"></i>{year}</h3>
            </div>
            <div className="panel-body stat-container">
              <div className="quick-facts col-xs-4 col-md-4 my-4">
                <div className="my-4"
                  key="count">{this.props.inputRole === 'Buyer'? 'Bought': 'Sold'}:
                  <span className="stat sales-count">
                    {stats[year].count}<i className="fa fa-home ml-2"></i>
                  </span>
                </div>
                {stats[year].count > 0 &&
                <div className="my-4"
                  key="sum">Sales Volume:
                  <span className="stat sales-volume">
                    {currencyFormat(stats[year].sum)}
                  </span>
                </div>}
                {stats[year].count > 0 &&
                <div className="my-4"
                  key="median">Median Price:
                  <span className="stat median-price">
                    {currencyFormat(stats[year].median)}
                  </span>
                </div>}
              </div>
              {stats[year].count > 0 &&
              <div className="col-xs-6 col-md-8 my-4">
              <Bar data={priceData[year]} options={PriceChartOptions}/>
              </div>}
              {stats[year].count > 0 &&
              <div className="col-xs-6 col-md-6 my-4">
              <Doughnut data={cityData[year]} options={CityCharOptions}/>
              </div>}
              {stats[year].count > 0 &&
              <div className="col-xs-6 col-md-6 my-4">
              <Pie data={ptypeData[year]} options={PTypeCharOptions}/>
              </div>}
            </div>
            <div className="line my-4"></div>
          </div>
        )}
        </div>
    );
  }
}

import * as types from '../actions/actionTypes';
import initialState from './initialState';

// Updates error message to notify about the failed fetches.
const errorMessage = (state = initialState.error, action) => {
  switch (action.type) {
    case types.RESET_ERROR_MESSAGE:
      return initialState.error;
    case types.ERROR_MESSAGE:
      return action.error;
    default:
      return state;
  }
};

export default errorMessage;
